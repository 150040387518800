<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
    >
      <el-form-item prop="role_type" label="身份">
        <el-select v-model="form.data.role_type" placeholder="请选择" @change="changeRoleType" style="width:100%;" :disabled="isChange">
          <el-option label="师傅" :value="1"></el-option>
          <el-option label="代理商" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="type" label="抽佣对象" v-if="form.data.role_type == 1">
        <el-select v-model="form.data.type" placeholder="请选择" style="width:100%;" :disabled="isChange">
          <el-option label="普通师傅" :value="1"></el-option>
          <el-option label="代理师傅" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="type" label="抽佣对象" v-else-if="form.data.role_type == 2">
        <el-select v-model="form.data.type" placeholder="请选择" style="width:100%;" :disabled="isChange">
          <el-option label="代理商" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="type" label="抽佣对象"  v-else>
        <el-select v-model="form.data.type" placeholder="请选择" style="width:100%;" :disabled="isChange">
         <el-option label="普通师傅" :value="1"></el-option>
          <el-option label="代理师傅" :value="2"></el-option>
          <el-option label="代理商" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="value" label="抽成占比">
        <el-input
          v-model="form.data.value"
          clearable
          placeholder="请输入"
        ><span slot="suffix">%</span></el-input>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <el-radio-group v-model="form.data.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import MyVueCroppers from "@/components/MyVueCroppers.vue";
  export default {
    name: 'AgencyCommisionEdit',
    props:['areaArr'],
    components: {
      MyVueCroppers,
    },
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            type: '',
            value: '',
            status: 1, //状态 1:正常 2:禁用
            role_type: '', // 1:师傅，2：代理商
          },
          rules: {
            role_type: [{required: true, message: "请选择身份", trigger: "change"}],
            type: [{required: true, message: "请选择抽佣对象", trigger: "change"}],
            value: [
              {required: true, message: "请输入抽成占比", trigger: "change"},
              {
                pattern: /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,2})?$/,
                message: "占比不超过100",
              },
            ],
            status: [{required: true}],
          }
        },
        isIndeterminate: false,
        checkAll: false,
        checkedCities: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            const { id, type, value, status, role_type } = this.form.data;
            let apiUrl = !this.isChange ? '/admin/flex_commission/add' : '/admin/flex_commission/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, { id, type, value, status, role_type }).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      changeRoleType() {
        this.form.data.type = ''
      }
    }
  }
</script>
