var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-view"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","icon":"el-icon-plus"},on:{"click":_vm.showEdit}},[_vm._v("设置抽佣")])],1),_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true,"showSelect":false},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"role_type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.role_type == 1 ? '师傅' : (row.role_type == 2 ? '代理商' : '')))])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toStr({1:'普通师傅', 2:'代理师傅', 3:'代理商'}, row.type)))])]}},{key:"value",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.value)+"%")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.status == 1 ? '正常' : '禁用'))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.showEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{class:row.status == 1 ? 'delColor' : 'sussColor',attrs:{"type":"text"},on:{"click":function($event){return _vm.handleStatus(row)}}},[_vm._v(_vm._s(row.status == 1 ? '关闭' : '开启'))])]}}])}),_c('edit',{ref:"edit",attrs:{"areaArr":_vm.areaArr},on:{"refresh":_vm.getTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }